<template>
  <div>
    <div class="d-flex justify-content-between align-items-end">
      <div class="mb-1">
        <h3 class="">Transmission Services</h3>
      </div>
      <b-button variant="relief-primary" @click="$refs['create-service-modal'].show()" class="mb-2" v-if="!userCantEdit">
        <feather-icon icon="PlusIcon" />
        Add Service
      </b-button>
    </div>

    <template v-if="Array.isArray(services)">
      <div class="rounded overflow-hidden">
        <b-table
          :items="services"
          :fields="tableFields"
          sort-icon-left
          hover
          sort-by="id"
          class="mb-0"
          @row-clicked="goToServiceInfo"
          show-empty
        >
          <template #cell(id)="data">
            <b-avatar class="border-secondary">{{data.item.id}}</b-avatar>
          </template>

          <template #cell(identifier)="data">
            <h5>{{data.item.identifier}}</h5>
          </template>

          <template #head(actions)><div/></template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-end align-items-center animate__faster" v-if="!userCantEdit">
              <div class="w-100px" align="right">
                <div class="animate__animated animate__fadeInRight  visibility-hidden" v-if="optionsVisible==data.item.id" :id="'delete-btn-'+data.item.id">
                  <b-button variant="outline-danger" class="py-50 px-50 animate__animated animate__rotateIn " @click="openModal('delete-service', data.item)">
                    <b-icon icon="trash" scale="1" />
                  </b-button>   
                </div>
              </div>

              <b-button variant="none" @click="optionsVisible = data.item.id">
                <b-icon icon="three-dots-vertical" scale="1.3" />
              </b-button>
            </div>
          </template>

          <template #empty>
            <h4 class="text-center text-secondary my-2">No Services yet</h4>
          </template>

        </b-table>
      </div>
      <b-pagination   
        v-model="table.currentPage"  
        :total-rows="services.length" 
        :per-page="table.rowsPerPage"
        prev-class=""
        next-class=""
        first-number
        last-number
        :limit="3"
        align="center"
        class="my-1"
      />
    </template>
    <div v-else>
      <b-skeleton-table :rows="5" :columns="4" />
    </div>

    <b-modal
      ref="delete-service"
      @hide="((e) =>{ if (modalInfo.loading) e.preventDefault()})"
      centered
      ok-variant="danger"
      @ok="deleteService()"
      :ok-disabled="modalInfo.loading"
    >
      <template #modal-title> 
        <div>
          Confirm Delete
        </div>
      </template>

      Do you want to delete the Service <b>{{modalInfo.item.identifier}}</b>?

      <template #modal-ok>
        <div>
          <span v-if="!modalInfo.loading">Confirm</span>
          <b-spinner small v-else/>

        </div>
      </template>

    </b-modal>

    <create-service-modal ref="create-service-modal"/>
    
  </div>
</template>

<script>
import custom from "@/custom";
import BaseModal from "@/layouts/components/BaseModal.vue";
import { 
  BButton,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BForm,
  BTable,
  BAvatar,
  BPagination,
  BSpinner,
  BSkeletonTable
 } from "bootstrap-vue";
import CreateServiceModal from "@/views/pages/middleware/MiddlewareManagement/Components/Modals/CreateServiceModal.vue"
import { mapGetters } from "vuex";
import { makeToast } from "@/layouts/components/Popups";


export default {
  components: {
    BaseModal,
    CreateServiceModal,
    BButton,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BForm,
    BTable,
    BAvatar,
    BPagination,
    BSpinner,
    BSkeletonTable
  },
  data() {
    return {
      table:{
        currentPage: 1,
        rowsPerPage: 10
      },
      optionsVisible: '',
      tableFields: [
        {
          key: "id",
          label: this.$t("common.table.fields.id"),
          sortable: true,
        },
        {
          key: "identifier",
          label: this.$t("common.table.fields.name"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("common.table.fields.created_at"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("common.table.fields.actions"),
          sortable: false
        },
      ],
      modalInfo:{
        loading: false,
        item: {
          event_identifier: ""
        }
      },
    };
  },
  created() {
    this.$root.$refs["this.$route.meta.pageTitle"] = this;
  },
  mounted() {
    this.buildActions();
    this.init()
  },
  computed: {
    ...mapGetters(["getSelectedTransmissionGroup", "getTransmissionServices"]),
    services() {
      return this.getTransmissionServices 
    },
    userCantEdit(){
      return !this.$can('create')
    }
  },
  methods: {
    init(){
      this.$store.dispatch('getServicesByTransmissionGroup', {transmissionID: this.$route.params.transmissionID})
        .then(()=>{
        })
        .catch((err)=>{
          console.error(err)
          makeToast({
            title: this.$t('common.toast.error.title'),
            text:  this.$t('common.toast.error.message'),
            variant: "danger",
            icon: "XIcon",
          });
        })
    },
    buildActions() {
    },
    openMiddlewares: function (item) {
      this.$router.push({
        name: "serviceMiddlewares",
        params: { serviceID: item.id },
      });
    },
    goToServiceInfo(item){
      this.$router.push({ name: "service-info", params: { serviceId: item.id } });
    },
    openModal(ref, item){
      this.modalInfo.item = item
      this.$refs[ref].show()
    },
    deleteService(){
      let item = this.modalInfo.item
      this.modalInfo.loading = true
      this.$store.dispatch('deleteServiceById', {transmissionID: this.$route.params.transmissionID, item: item})
        .then(()=>{
          this.modalInfo.loading = false
          makeToast({
            title: "Success!",
            text: "Service deleted successfully",
            variant: "success",
            icon: "CheckIcon",
          });
          this.$nextTick(()=>{
            this.$refs['delete-service'].hide()
          })
        })
        .catch((err)=>{
          console.error(err)
        })
        .finally(()=>{
          this.modalInfo.loading = false
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.w-100px{
  width: 100px;
}
</style>